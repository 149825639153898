import React from "react";

import "./Header.css";
import { Box, Nav, Grommet, Header, Text } from "grommet";
import { Hpe, Search, Notification, CircleQuestion, Down } from "grommet-icons";
import { grommet } from "grommet/themes";

export const Simple = () => (
  <Grommet theme={grommet}>
    <Header background="white" pad="small">
      <Box
        className="app-logo"
        direction="row"
        align="center"
        gap="medium"
        pad={{ vertical: "xsmall" }}
        justify="center"
      >
        <Hpe color="#00c781" size="35px" />
        <Text color="black" size="17px">
          <b>HPE</b> Ezmeral Container Platform
        </Text>
      </Box>
      <Nav direction="row" align="center" gap="small">
        <Search size="18px" color="#444444" />
        <Notification size="18px" color="#444444" />
        <CircleQuestion size="18px" color="#444444" />
        <Text size="small" weight="800" color="black">
          Elias Brandt
        </Text>
        <Down size="18px" color="black" />
      </Nav>
    </Header>
  </Grommet>
);

export default Simple;
