import React, { useState } from "react";
import "./SideBar.css";
import {
  Accordion,
  AccordionPanel,
  Button,
  Box,
  Grommet,
  Nav,
  Sidebar,
  Text,
  Tip,
  Collapsible,
} from "grommet";
import { StatusGoodSmall, Close, Menu, Radial, Cursor } from "grommet-icons";

import data from "../../assets/data/menus.json";

const customTheme = {
  tip: {
    drop: {
      align: { left: "right" },
    },
    content: {
      margin: "xxsmall",
      pad: "xxsmall",
      background: { color: "#263040" },
      round: { size: "xxsmall" },
      // flex: false, // so Tip won't get cut on a window resize
    },
  },
  // collapsible: {
  //   minSpeed: 400,
  // },
  accordion: {
    border: {
      color: "#263040",
    },
  },
  // button: { height: "44px" },
};

const SidebarAccordion = ({ menuItem, ...rest }) => (
  <Box {...rest} fullWidth>
    {/* {({ hover }) => ( */}
    <AccordionPanel
      // background={hover ? "dark-5" : undefined}
      label={
        <Box
          // background={hover ? "dark-5" : undefined}
          pad={{ horizontal: "small", vertical: "xxxsmall" }}
          direction="row"
          align="center"
        >
          <StatusGoodSmall size="16px" color="#3b4e68" />
          <Text margin="small" color="white" weight="bold" size="small">
            {menuItem.title}
          </Text>
        </Box>
      }
      className="dropdown-btn"
    >
      {menuItem.subMenus.map((subMenu) => (
        <SidebarButton menuItem={subMenu} isSubMenu={true} />
      ))}
    </AccordionPanel>
    {/* )} */}
  </Box>
);

const SidebarButton = ({ menuItem, isSubMenu, ...rest }) => (
  <Button plain {...rest} fullWidth>
    {/* {({ hover }) => ( */}
    <Box
      // background={hover ? "dark-5" : undefined}
      pad={{ horizontal: "small", vertical: "0px" }}
      direction="row"
      align="center"
    >
      {isSubMenu ? (
        <Box
          direction="row"
          align="center"
          pad={{ horizontal: "small" }}
          className="full-width"
        >
          <Box className="full-width">
            <Text margin="small" color="white" size="small">
              {menuItem.title}
            </Text>
          </Box>
          <Box alignSelf="end">
            <Text margin="small" color="white" size="small" alignSelf="end">
              0
            </Text>
          </Box>
        </Box>
      ) : (
        <Box direction="row" align="center">
          <StatusGoodSmall size="16px" color="#3b4e68" />
          <Text margin="small" color="white" weight="bold" size="small">
            {menuItem.title}
          </Text>
        </Box>
      )}
    </Box>
    {/* )} */}
  </Button>
);

export const CustomSideBar = (props) => {
  const [showSidebar, setShowSidebar] = useState(false);
  const { clicked } = props;
  const footerData = [
    "Whats New",
    "Getting Started",
    "Documentation",
    "API Reference",
  ];

  const MainNavigationCollapsed = () => (
    <Box
      className="main-nav"
      pad={{ horizontal: "none", vertical: "xxsmall" }}
      overflow="auto"
    >
      <Nav gap="xxsmall" fullWidth>
        <Tip content="Home" dropProps={{ align: { left: "right" } }}>
          <Button
            icon={<StatusGoodSmall size="16px" color="#3b4e68" />}
            className="fix-btn-height"
          />
        </Tip>
        {data
          .filter((item) => item.userRole === clicked.role)
          .map((menuItem) => (
            <Tip
              content={menuItem.title}
              dropProps={{ align: { left: "right" } }}
            >
              <Button
                icon={<StatusGoodSmall size="16px" color="#3b4e68" />}
                className="fix-btn-height"
              />
            </Tip>
          ))}
      </Nav>
    </Box>
  );

  const MainNavigationExpanded = () => (
    <Box
      className="main-nav"
      pad={{ horizontal: "none", vertical: "xxsmall" }}
      overflow="auto"
    >
      <Accordion animate={true} multiple="true" gap="xxsmall" align="start">
        <SidebarButton
          className="sidebar-btn"
          menuItem={{ title: "Home" }}
          isSubMenu={false}
        />
        {data
          .filter((item) => item.userRole === clicked.role)
          .map(renderButton)}
      </Accordion>
    </Box>
  );

  function renderButton(menuItem) {
    return menuItem && menuItem.subMenus ? (
      <SidebarAccordion menuItem={menuItem} className="sidebar-btn" />
    ) : (
      <SidebarButton
        menuItem={menuItem}
        isSubMenu={false}
        className="sidebar-btn"
      />
    );
  }

  const SidebarFooter = ({ footerData, ...props }) => (
    <Box
      className="sidebar-footer"
      pad={{ horizontal: "none", vertical: "xxsmall" }}
      overflow="auto"
    >
      <Box pad="xxsmall" border={{ color: "#3b4e68", side: "bottom" }} />
      <Nav gap="xxsmall">
        {footerData.map((item) => (
          <Tip content={item} dropProps={{ align: { left: "right" } }}>
            <Button
              icon={<StatusGoodSmall size="16px" color="#3b4e68" />}
              className="fix-btn-height"
            />
          </Tip>
        ))}
      </Nav>
    </Box>
  );

  return (
    <Grommet theme={customTheme}>
      <Box
        className="sidebar-container"
        direction="row"
        height={{ min: "100%" }}
      >
        <Sidebar
          pad="none"
          gap="small"
          background="#263040"
          header={
            <Box pad={{ horizontal: "small" }} className="sidebar-header">
              <Menu
                color="white"
                onClick={() => setShowSidebar(!showSidebar)}
              />
            </Box>
          }
          footer={<SidebarFooter footerData={footerData} />}
        >
          <MainNavigationCollapsed />
        </Sidebar>
      </Box>
      {/* <Box
        className="container-expanded"
        direction="row"
        height={{ min: "100%" }}
      > */}
      <Collapsible direction="horizontal" open={showSidebar}>
        <Box
          className="sidebar-container"
          direction="row"
          height={{ min: "100%" }}
        >
          <Sidebar
            pad="none"
            gap="small"
            className="sidebar-expanded"
            background="#263040"
            header={
              <Box pad={{ horizontal: "small" }} className="sidebar-header">
                <Close
                  color="white"
                  onClick={() => setShowSidebar(!showSidebar)}
                />
              </Box>
            }
            footer={
              <Box
                className="sidebar-footer"
                pad={{ horizontal: "none", vertical: "xxsmall" }}
                overflow="auto"
              >
                <Box
                  pad="xxsmall"
                  border={{ color: "#3b4e68", side: "bottom" }}
                />
                <Nav gap="xxsmall">
                  {footerData.map((label) => (
                    <SidebarButton
                      menuItem={{ title: label }}
                      isSubMenu={false}
                    />
                  ))}
                </Nav>
              </Box>
            }
          >
            <MainNavigationExpanded />
          </Sidebar>
        </Box>
      </Collapsible>
      {/* </Box> */}
    </Grommet>
  );
};

export default CustomSideBar;
