import React, { useEffect, useState } from "react";
import SideBar from "./Components/SideBar";
import Header from "./Components/Header";
import { Box, Button, Grommet, grommet, Nav, Text } from "grommet";

import data from "./assets/data/userRoles.json";
import { colors } from "grommet/themes/base";

const SidebarButton = ({ roleItem, handleClick, ...rest }) => {
  return (
    <Button
      className="sidebar-btn"
      plain
      {...rest}
      align="center"
      onClick={() => {
        handleClick(roleItem);
      }}
    >
      <Box
        background={roleItem.active ? "light-4" : "white"}
        pad={{ horizontal: "small", vertical: "xxsmall" }}
        direction="row"
        width="medium"
      >
        <Text margin="small" weight="bold" size="small">
          {roleItem.role}
        </Text>
      </Box>
    </Button>
  );
};

function App() {
  const [clicked, setClicked] = useState(data[0]);

  // useEffect(() => {
  //   clicked.active = true;
  // });

  const OnClickItemList = () => {
    const handleClick = (role) => {
      clicked.active = false;
      role.active = true;
      setClicked(role);
    };
    return (
      <Box align="center" pad="large" gap="large">
        <Nav gap="xsmall">
          <Box>Select Roles</Box>
          {data
            .filter((item) => item.type === "single")
            .map((roleItem) => (
              <SidebarButton
                roleItem={roleItem}
                key={roleItem.role}
                handleClick={handleClick}
              />
            ))}
          <Box>Combinatorial Roles</Box>
          {data
            .filter((item) => item.type !== "single")
            .map((roleItem) => (
              <SidebarButton
                roleItem={roleItem}
                key={roleItem.role}
                handleClick={handleClick}
              />
            ))}
        </Nav>
      </Box>
    );
  };

  return (
    <Grommet>
      <Box>
        <Header />
        <SideBar clicked={clicked} />
        <OnClickItemList />
      </Box>
    </Grommet>
  );
}

export default App;
